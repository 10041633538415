import React from 'react';
import {makeStyles} from "@mui/styles";
import { setFeedSource } from 'store/slices/EodDataSlice'
import { useDispatch } from 'react-redux';
import {
    Box, Divider,
} from "@mui/material"
import DefaultDataSetLogoIcon from 'components/icons/DefaultDataSetLogo';

const useStyles = makeStyles((theme) => ({
    ListItem: {
        padding: 12,
        gap: 12,
        background: '#F5F8FF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },
    ListItemDivider: {
        background: '#E5ECFA'
    },
    Selected: {
        background: 'white'
    }
}));

export default function ListItem(props) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { showDefaultLogo = false, logo = '' } = props

    return (
        <>
            <Box className={`${classes.ListItem} ${props.selected ? classes.Selected : ''}`} onClick={() => {
                if(props.onClick) props.onClick()
            }}>
                {
                    logo && logo !== '' ? (
                        <img src={logo} alt='feed-logo' style={{ width: '50px', height: '50px' }} />
                    ) : showDefaultLogo ? (
                        <Box>
                            <DefaultDataSetLogoIcon />
                        </Box>
                    ) : <></>
                }
                <Box style={{ flex: 1, maxWidth: 'calc(100% - 35px)', marginLeft: !showDefaultLogo ? 10 : 0 }}>
                    {props.children}
                </Box>
            </Box>
            <Divider
                className={`${classes.ListItemDivider}`}
            />
        </>
    );
}