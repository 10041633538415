import React from 'react'
import {SvgIcon} from "@mui/material";

const NonMarketIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="100"
      height="100"
      viewBox="0 0 128 160" x="0px" y="0px"
      // fillRule="evenodd"
      // clipRule="evenodd"
      fill="none"
    >
      <path
        d="M2.00146,67.92627a1.99518,1.99518,0,0,0,.76563-.15314C14.59229,62.87073,42.51453,48.40265,53.8158,17.85l.23572,1.02087a1.99971,1.99971,0,0,0,3.89746-.89734L56.00366,9.54889A2.00047,2.00047,0,0,0,52.7,8.52863L46.3089,14.41a1.99837,1.99837,0,1,0,2.7099,2.93774l1.09253-1.00519C39.38116,45.51007,12.57684,59.38068,1.23389,64.08337a1.99751,1.99751,0,0,0,.76758,3.8429Z"/>
      <path
        d="M118.7666,82.421a4.77317,4.77317,0,0,0-6.63269-.0683l-3.0354-3.1167a28.00685,28.00685,0,1,0-8.62292,9.06738l2.85712,2.93311a5.04,5.04,0,0,0,.12439,6.95209l21.12439,21.22229A1.9962,1.9962,0,0,0,125.99994,120,4.24164,4.24164,0,0,0,128,119V91Zm-33.76648,6.548A23.97032,23.97032,0,1,1,109,64.99878,24.01227,24.01227,0,0,1,85.00012,88.96906Zm21.133-.58655-2.4729-2.53882a28.13959,28.13959,0,0,0,3.11194-3.26678l2.534,2.60181Z"/>
      <path
        d="M80.00012,22.05194a4.00211,4.00211,0,0,0-3.99994-3.99506h-8a4.00212,4.00212,0,0,0-4,3.99506V40.88519a31.88781,31.88781,0,0,1,15.99994-7.492Z"/>
      <path
        d="M82.00012,103.9505h-2V96.60681a31.88781,31.88781,0,0,1-15.99994-7.492V103.9505H60.00024V84.94128a31.903,31.903,0,0,1,0-39.88257V42.02722a4.00208,4.00208,0,0,0-4-3.99506h-8a4.00207,4.00207,0,0,0-3.99994,3.99506V103.9505h-4V62.00244a4.00212,4.00212,0,0,0-4-3.99506H28.00037a4.00212,4.00212,0,0,0-4,3.99506V103.9505h-4V81.97772a4.00207,4.00207,0,0,0-3.99994-3.99506h-8a4.00207,4.00207,0,0,0-3.99994,3.99506V103.9505h-2a1.99753,1.99753,0,1,0,0,3.99506H82.00012a1.99753,1.99753,0,1,0,0-3.99506Z"/>
    </SvgIcon>
    // fill="none" xmlns="http://www.w3.org/2000/svg"
  );
};

export default NonMarketIcon;
