import React, { useMemo } from 'react';
import {
    Box,
} from "@mui/material"

import useStyles from './useStyles'
import ListItem from './Details/ListItem';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useSelector } from 'react-redux';

export default function StockSource({
    searchWord,
    changeStep2Index
}) {
    const classes = useStyles();
    const isLoading = useSelector(state => state.eodData.loadingFeedSources)
    const feedSources = useSelector(state => state.eodData.sources)

    const filteredFeedSources = useMemo(() => {
        let _feedSource = [...feedSources]
        if (searchWord && searchWord !== '') {
            _feedSource = feedSources.filter(item => item.feed_source?.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 || item.human_readable_name?.toLowerCase().indexOf(searchWord) > -1)
        }
        _feedSource = _feedSource.sort((a, b) => {
            // Use human_readable_name if available, otherwise use feed_source
            const aName = a?.human_readable_name || a?.feed_source;
            const bName = b?.human_readable_name || b?.feed_source;

            // Compare the names alphabetically
            // return aName - bName
            return aName.localeCompare(bName);
        })
        return _feedSource

    }, [
        feedSources,
        searchWord
    ])
    console.log(filteredFeedSources, 'filteredFeedSources')

    return (
        <Box id="FeedSourceWrapper">
            <LoadingOverlay isLoading={isLoading}>
                {
                    filteredFeedSources && filteredFeedSources.length < 1 && !isLoading ? (
                        <div style={{ marginTop: '10px', marginLeft: '18px' }}>There is no Feed Source</div>
                    ) : (
                        <Box className={classes.ListBox}>
                            {
                                filteredFeedSources?.map((item, index) => {
                                    return <ListItem
                                        onClick={() => {
                                            changeStep2Index(1, item.feed_source);
                                        }}
                                        key={index}
                                        logo={item.logo_path}
                                        showDefaultLogo={true}
                                    >
                                        {item.human_readable_name || item.feed_source}
                                    </ListItem>
                                })
                            }
                        </Box>
                    )
                }
            </LoadingOverlay>
        </Box>
    );
}